.kt-topMenu {
  .kt-header__topbar {
    .kt-header__topbar-item--user {
      .kt-header__topbar-user {
        .kt-badge--username {
          background: #495867;
          height: 45px !important;
          width: 45px !important;
          border-radius: 50% !important;
          color: #ffffff;
          font-size: 1.5rem;
          line-height: 1.75rem;
          font-weight: bold;
        }
      }
    }
    .kt-notification__item-container {
      padding: 5px 0;
      .kt-notification__item {
        padding: 5px 12px;
        border-radius: 15px;
        .kt-notification__item-title {
          color: black;
          font-weight: bold;
        }
        &:hover {
          background: #495867;
          .kt-notification__item-title {
            color: white;
          }
        }
        &:after {
          content: "";
        }
      }
    }
  }
}

.kt-header {
  height: 85px;
  z-index: 200;
  @media (max-width: 1024px) {
    height: 20px;    
  }
}

.kt-header__topbar {
  padding-right: 25px;
}

#top-menu-dropdown {
  border-radius: 15px 15px 15px 15px;
  padding: 8px 12px;
}

.kt-notification__custom {
  border-top: 1.3px solid #d1d1d1 !important;
  padding: 5px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
