.node-title {
  font-size: 16px;
  font-weight: bold;
}
.answer-item {
  position: relative;
  min-width: 300px;
  max-width: 500px;
  background: #eee;
  background: #73afff;
  border-radius: 5px;
  border: 1px solid #73afff;
  padding: 12px 15px;
}
.package-tool {
  position: absolute;
  top: 0;
  right: -70px;
  .package-tool-link {
    margin: 0 5px;
    font-size: 1.5rem;
  }
  &.no-child {
    right: -40px;
  }
}
