body {
  font-size: 14px !important;
}
.btn-block {
  width: 100%;
}
.kt-login.kt-login--v3 {
  background-size: cover;
  background-repeat: no-repeat;
  .kt-login__wrapper {
    padding: 6% 2rem 1rem 2rem;
    margin: 0 auto 2rem auto;
    overflow: hidden;
    @media (max-width: 1024px) {
      padding-top: 5rem;
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      padding: 12% 0.5rem 0.5rem 0.5rem;
    }
    .kt-login__container {
      width: 31rem;
      margin: 0 auto;
      padding: 4rem;
      border-radius: 20px;
      background: white;
      @media (max-width: 1024px) {
        margin: 0 auto;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (max-width: 480px) {
        padding: 4rem 1.5rem;
      }
      .kt-login__head {
        // margin-top: 1rem;
        margin-bottom: 3rem;
        .sign-grey-bar {
          height: 25px;
          width: 180px;
          background: #c9d1d8;
          margin: auto;
          margin-top: 20px;
        }
        .kt-login__title {
          text-align: center;
          font-weight: bold;
        }
      }
      .kt-login__logo {
        text-align: center;
        .logo_black {
          width: 100px;
          height: 100px;
          margin: 30px;
        }
      }
      .kt-form {
        padding: 10px;
        .kt-checkbox {
          float: right;
          padding: 3px;
          padding-left: 25px;
        }
        .kt-login__extra .kt-login__link {
          // color: $secondary-color-1;
          -webkit-transition: color 0.3s ease;
          transition: color 0.3s ease;
          &:hover {
            // color: $primary-color-5;
            -webkit-transition: color 0.3s ease;
            transition: color 0.3s ease;
            cursor: pointer;
          }
        }
        .input-group {
          // color: $secondary-color-2!important;
          border: none;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          margin-bottom: 1rem !important;
          /* background: rgba(235, 237, 242, 0.4); */
          // border-bottom: 1px solid #27272770;
          width: 100%;
          border-bottom: 1px solid #27272770 !important;
          input {
            // color: $secondary-color-2!important;
            width: 100% !important;
            border: none;
            height: 30px;
            padding: 0;
            &:focus {
              border: none !important;
              // color: $secondary-color-2!important;
            }
          }
        }
        &:focus {
          border: none !important;
          border-bottom: 1px solid #27272770;
        }
        &::-moz-placeholder {
          opacity: 1;
        }
        .is-invalid + .invalid-feedback {
          font-weight: 500;
          font-size: 0.9rem;
          padding-left: 1.6rem;
        }
        .input-group {
          padding: 0;
          margin: 0 auto;
          label {
            font-size: 1rem;
            width: 100%;
          }
        }
        .kt-login__actions {
          text-align: center;
          margin-top: 7%;
          #kt_login_signin_submit {
            height: 40px;
            width: 160px;
            padding: 7px;
          }
        }
        @media (max-width: 768px) {
          width: 100%;
          margin: 0 auto;
        }
      }
      .kt-login__account {
        text-align: center;
        margin-top: 2rem;
        @media (max-width: 1024px) {
          margin-top: 1rem;
        }
        @media (max-width: 768px) {
          margin-top: 1rem;
        }
        .kt-login__account-msg {
          font-size: 1rem;
          font-weight: 400;
          // color: $secondary-color-4;
        }
        .kt-login__account-link {
          font-size: 1rem;
          font-weight: 500;
          // color: $secondary-color-1;
          -webkit-transition: color 0.3s ease;
          transition: color 0.3s ease;
          &:hover {
            // color: $primary-color-5;
            -webkit-transition: color 0.3s ease;
            transition: color 0.3s ease;
          }
        }
      }
      .kt-login__signin p {
        text-align: center;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
.kt-login--signin {
  .kt-login__signup {
    display: none;
  }
  .kt-login__signin {
    display: block;
  }
  .kt-login__forgot {
    display: none;
  }
}
.kt-login--signup {
  .kt-login__signup {
    display: block;
  }
  .kt-login__signin {
    display: none;
  }
  .kt-login__forgot {
    display: none;
  }
  .kt-login__account {
    display: none;
  }
}
.kt-login--forgot {
  .kt-login__signup {
    display: none;
  }
  .kt-login__signin {
    display: none;
  }
  .kt-login__forgot {
    display: block;
  }
}

/* Custom */

.bg-image {
  background: url("../../assets/images/bg3.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  .footer-bg {
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 0px;
    margin: 0;
    padding: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
}
form {
  .profile-info {
    .form-control {
      // color: $secondary-color-2 !important;
      &:focus {
        background-color: transparent !important;
        // color: $secondary-color-2 !important;
        border: none;
        border-bottom: 1px solid #27272770 !important;
        box-shadow: none !important;
      }
    }
  }
}

// Custom CODE
.kt-register .kt-login__wrapper .kt-login__container {
  padding: 2rem !important;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1rem !important;
  }
  .pricing_plan-body {
    padding: 15px 0;
    .pricing_plan-shortCode {
      padding: 5px 0;
      .pricing_plan-shortCode_title {
        text-align: right;
        color: #2e85d7;
        text-transform: capitalize;
      }
      .pricing_plan-shortCode_desc {
        text-align: left;
        color: #272727;
      }
      &:last-child {
        border-top: 1px solid #c9d1d8;
      }
    }
    .pricing_plan-table {
      text-align: center;
      padding: 10px;
      .ReactTable {
        margin-top: 1rem;
        border: none;
        .rt-thead {
          box-shadow: none;
          .rt-th {
            color: #2e85d7;
            border-right: none;
            border-left: none;
          }
        }
        .rt-tbody {
          color: #272727;
          .rt-tr-group {
            border-bottom: solid 1px rgb(210, 210, 210);
            .rt-td {
              border-right: none;
              border-left: none;
            }
          }
        }
      }
    }
    .pricing_plan-details {
      font-weight: bold !important;
      text-align: center;
      //background-color: #e4e8eb;
      //height: 40px;
      border-bottom: 1px solid #c9d1d8;
      padding: 10px;
      .pricing_plan-link {
        text-decoration: underline;
        cursor: pointer;
        color: #005daa;
      }
      .pricing_plan-total {
        color: #005daa;
      }
    }
    .pricing_plan-color {
      color: #005daa !important;
    }
  }
  .pricing_plan-footer {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    .pricing_plan-terms {
      text-align: center;
      color: #272727;
      //width: 320px;
      display: inline-block;
      a {
        color: #272727;
        text-decoration: underline;
      }
    }
    .pricing_plan-register {
      #btn_pp_confirm {
        padding: 7px 2.25rem 4px 2.25rem;
        margin: 1.5rem 0 1.5rem;
      }
    }
  }
}
.profile-container {
  .nav {
    font-weight: 600;
    .active {
      background: #57b848 !important;
      color: white !important;
    }
    .nav-link {
      padding: 0.8rem 2.5rem;
    }
  }
}

.register-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 1023px) {
    height: auto;
    .nav {
      border-right: none;
      border-bottom: 1px solid #ccc;
    }
  }
  .error {
    font-size: 0.9rem;
    color: red;
  }
  .error-invalid {
    font-size: 0.9rem;
    color: red;
  }
  .tab-content {
    padding: 2.5rem;
    .custom-landing-form {
      margin-bottom: 0;
      // font-weight: bold;
      label {
        font-weight: bold;
      }
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        padding: 0;
        height: 30px;
      }
    }
    .profile-form-input,
    .business-form-input {
      label {
        font-weight: bold;
      }
    }
  }
  .payment-form-group {
    .payment-form-input {
      margin-bottom: 2rem;
    }
  }
  .profile-form-input {
    margin-bottom: 2rem;
  }
  .business-form-input {
    margin-bottom: 2rem;
  }
}
// .tab-content {
//   .tab-pane {
//     margin-left: 60px;
//   }
// }

/* Footer area */
.footer-area-account {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;
  @media (max-width: 1024px) {
    display: none;
  }
  .footer-background {
    height: 308px;
    background-image: url(../../assets/images/wave_short.png);
    background-repeat: no-repeat !important;
    background-size: initial !important;
    position: relative;
    @media (min-width: 1921px) {
      background-size: cover !important;
    }
    .footer-logo {
      position: absolute;
      top: 64%;
      transform: translateY(-50%);
      right: 60px;
      @media (max-width: 575px) {
        display: none;
      }
    }
    .footer-top {
      padding-bottom: 2rem;
      position: absolute;
      bottom: 0;
      width: 95%;
      .footer-widget {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (max-width: 992px) {
          &.about_widget {
            .copyright-inner {
              margin-left: 3rem;
            }
          }
        }
        &.additional-info {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            font-size: 1.1rem;
            margin-left: 20px;
            a {
              color: white;
              text-decoration: none;
              &:hover {
                color: white;
                text-decoration: underline !important;
              }
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        .copyright-inner {
          color: white;
          font-size: 1.1rem;
          margin-left: 5rem;
          .left-content-area {
            text-align: right;
          }
        }
        .social-icon {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            display: inline-block;
            margin: 0 8px;
            a {
              display: flex;
              width: 45px;
              height: 45px;
              font-size: 1.8rem;
              background: white;
              border-radius: 50%;
              color: #4477ba;
              i {
                margin: auto;
              }
              &:hover,
              &:focus,
              &:active {
                background: #d7e8ff;
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        margin-left: 2rem;
        .footer-widget {
          justify-content: flex-start;
        }
        .copyright-inner {
          width: 100%;
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
      }
      @media (max-width: 480px) {
        margin-left: 1rem;
        .footer-widget {
          justify-content: flex-start;
          li {
            font-size: 0.9rem !important;
          }
        }
        .social-icon a {
          width: 38px !important;
          height: 38px !important;
        }
        .copyright-inner {
          width: 100%;
          margin-left: 0 !important;
          padding-left: 0 !important;
          font-size: 0.9rem !important;
        }
      }
    }
  }
}

p {
  font-size: unset;
}
