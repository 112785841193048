body {
  color: #272727;
}

.rt-th {
  input[type="text"] {
    color: black !important;
  }
}

.invalid {
  color: #fd397a !important;
}

.search-input {
  border: 1px solid #4958677d !important;
  border-radius: 28px;
  height: 32px;
  margin-top: 9px;
  float: right;
  &::placeholder {
    padding-left: 10px;
  }
  &:focus {
    color: #272727;
    padding-left: 10px;
    outline: none;
  }
}

.search {
  position: relative;
  float: right;
  right: 35px;
  top: 13px;
}

.-pageJump {
  input[type="number"] {
    color: black !important;
  }
}

.rt-td {
  button {
    margin-left: 5px;
  }
}

.page-title {
  display: initial;
}

.add-btn {
  padding: 0.5rem 25px !important;
  margin-left: 15px !important;
  margin-bottom: 5px;
}

.kt-form__actions button {
  margin-right: 10px;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.page-title {
  color: black;
}

.undefined-label {
  label {
    display: none;
  }
}
.kt-user {
  padding: 25px;
  background: #fff;
}

.user-table {
  border-radius: 20px;
}

.users-list {
  position: relative;
  .users-list-tools {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    .users-list-tools-company {
      display: inline-block;
      width: 200px;
      margin-right: 15px;
    }
    .users-list-tools-search {
      display: inline-block;
      width: 200px;
    }
  }
}
