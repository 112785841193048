.popup-container {
  width: 700px;
  .bp3-dialog-body {
    overflow: auto;
    max-height: 500px;
    margin:0;
    padding:20px;
  }
}

.terms-conditions,
.privacy {
  a {
    color: #4477ba;
  }
  p {
    font-size: 14px;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #4477ba;
  }
  .entry-content {
    h2 {
      text-align: center;
    }
    h5{
      color: #333;
    }
    span{
      font-weight: 700;
    }
   ol {
     li {
      line-height: 22px;
     }
   }
  }
}
.kt-store__width {
  margin: 2px 0;
  display: block;
  img {
    width: 150px;
  }
}

.kt-store__badge {
  margin: 2px 0;
  display: block;
  img {
    height: 48px;
    width: auto;
  }
}