/* ========= LandingPage ==========*/
body {
  background: #f6f8f9;
  font-size: 14px;
  // .video-wrap {
  //   display: none;
  // }
  @media (max-width: 767px) {
    overflow: hidden;
  }
  .header-area.header-bg {
    @media (max-width: 1024px) {
      height: unset;
      padding-bottom: 2rem;
    }
  }
  .header-area {
    padding: 0;
    /* LoginIn */
    #login-btn {
      margin: 2rem 0;
      font-size: 14px;
    }
    .img-with-video {
      @media (min-width: 320px) and (max-width: 480px) {
        display: none;
      }
    }
    .video-wrap {
      iframe {
        border-width: 0;
      }
      @media (min-width: 481px) and (max-width: 767px) {
        display: none;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }
      @media (min-width: 1024px) and (max-width: 2500px) {
        display: none;
      }
      @media (min-width: 2500px) and (orientation: landscape) {
        display: none;
      }
      @media (min-width: 768px) and (max-width: 2000px) and (orientation: landscape) {
        display: none;
      }
    }
    .headerItem {
      .page-logo {
        margin-top: 2rem;
        margin-left: 2rem;
        width: auto;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 120px;
        }
        @media (min-width: 481px) and (max-width: 767px) {
          width: 136px;
          // margin: -65px 0 50px 0;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          // margin-left: 56px;
        }
      }
    }
    .video-play-btn {
      color: setColor(primary-2) !important;
    }
  }
  .counterup-area {
    @media (min-width: 320px) and (max-width: 480px) {
      padding: 30px 0 30px 0;
      margin-left: 0px !important;
      margin-top: 0px !important;
      border-radius: 0 !important;
    }
    @media (min-width: 481px) and (max-width: 767px) {
      border-radius: 0 !important;
      margin-left: 0;
      padding: 2rem;
      margin-top: 0px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 50px 0 50px 0;
      margin-left: 0;
      border-radius: 0;
      margin-top: 0px;
    }
    .header-right-image {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 500px;
      min-width: 500px;
      img {
        position: absolute;
        bottom: 0;
      }
      @media (min-width: 320px) and (max-width: 480px) {
        max-width: unset;
        min-width: unset;
        padding-right: 20px;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        left: 20px;
        width: 95%;
        height: 100%;
        max-width: unset;
        min-width: unset;
        padding-right: 20px;
        img {
          max-height: 100%;
          top: 0;
          bottom: unset;
        }
      }
      @media (min-width: 1025px) and (max-width: 1280px) {
        max-width: unset;
        min-width: unset;
        padding-right: 20px;
      }
    }
    .search-content {
      padding-right: 3rem;
      @media (max-width: 767px) {
        padding-right: 15px;
      }
      .search-title {
        h1 {
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: 22px;
          }
        }
      }
      .search-button {
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
          width: 180px;
          right: 60px;
        }
      }
    }
  }
  /* Content/Feature section */
  .why-choose-area.why-choose-us-bg {
    background: #f6f8f9;
    @media (min-width: 320px) and (max-width: 480px) {
      padding: 62px 0 0 0 !important;
    }
    @media (min-width: 481px) and (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 0;
    }
    .contentOne {
      .single-why-us-item {
        box-shadow: 0px 1px 20px #aba6a6a6;
        p {
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: 14px;
          }
        }
        .icon {
          @media (min-width: 320px) and (max-width: 480px) {
            width: 70px;
            height: 70px;
          }
        }
        .title {
          font-size: 1.4rem;
          font-family: "Comfortaa", cursive;
          margin-bottom: 0;
        }
        @media (min-width: 320px) and (max-width: 480px) {
          margin: 0 0 90px 0;
          padding: 36px 8px;
        }
        &:hover {
          cursor: pointer;
        }
        @media (min-width: 481px) and (max-width: 767px) {
          margin: 0 0 90px 0;
        }
      }
      .background-border {
        height: 150px;
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 50%;
        border-top-left-radius: 150px;
        border-bottom-left-radius: 150px;
        top: 180px;
        @media (min-width: 320px) and (max-width: 480px) {
          top: 73px !important;
          height: 115px;
        }
        @media (min-width: 481px) and (max-width: 767px) {
          top: 108px;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          top: 131px !important;
        }
      }
    }
    .contentTwo {
      .single-why-us-item {
        box-shadow: 0px 1px 20px #aba6a6a6;
        p {
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: 14px;
          }
        }
        .icon {
          @media (min-width: 320px) and (max-width: 480px) {
            width: 70px;
            height: 70px;
            line-height: 69px;
          }
          i {
            @media (min-width: 320px) and (max-width: 480px) {
              font-size: 34px;
            }
          }
        }
        .title {
          font-size: 1.4rem;
          font-family: "Comfortaa", cursive;
          margin-bottom: 0;
        }
        @media (min-width: 320px) and (max-width: 480px) {
          margin: 0 0 90px 0;
          padding: 36px 8px;
        }
        &:hover {
          cursor: pointer;
        }
        @media (min-width: 481px) and (max-width: 767px) {
          margin: 0 0 90px 0;
          // padding: 10px 13px;
        }
      }
      .background-border {
        height: 150px;
        background: setColor(primary-2);
        position: absolute;
        z-index: 2;
        width: 100%;
        right: 50%;
        border-top-right-radius: 150px;
        border-bottom-right-radius: 150px;
        bottom: 75px;
        @media (min-width: 320px) and (max-width: 480px) {
          bottom: 102px;
          height: 115px;
        }
        @media (min-width: 481px) and (max-width: 767px) {
          bottom: 114px;
        }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          // top: 131px;
        }
      }
    }
  }
  /* Contact Us Area */
  .contact-form-area {
    @media (min-width: 320px) and (max-width: 480px) {
      padding-bottom: 20px;
    }
    .contact-area-wrapper {
      @media (min-width: 320px) and (max-width: 480px) {
        padding: 0px 20px;
      }
      .submit-btn {
        font-size: 15px;
        height: 36px;
      }
      .title {
        font-size: 2rem;
      }
    }
    .contact-form {
      .form-group {
        border-bottom: 1px solid #bdbfbf !important;
        margin-bottom: 20px;
        label {
          font-size: 1rem;
          margin-bottom: 0;
          font-weight: 600;
          width: 100%;
        }
        .contactForm {
          background: transparent;
          width: 100% !important;
          color: setColor(primary-5) !important;
          border: none;
          height: auto;
          margin-top: 5px;
          &:focus {
            border: none !important;
            box-shadow: none;
          }
        }
      }
    }
  }
  /* Footer area */
  .footer-area {
    .footer-background {
      height: 308px;
      background-image: url(./images/wave_short.png);
      background-repeat: no-repeat !important;
      background-size: initial !important;
      position: relative;
      .footer-logo {
        position: absolute;
        top: 64%;
        transform: translateY(-50%);
        right: 60px;
        @media (max-width: 575px) {
          display: none;
        }
      }
      .footer-top {
        padding-bottom: 2rem;
        position: absolute;
        bottom: 0;
        width: 95%;
        .footer-widget {
          float: right;
          @media (max-width: 992px) {
            &.about_widget {
              .copyright-inner {
                margin-left: 3rem;
              }
            }
          }
          .social-icon {
            a {
              display: flex;
              width: 45px;
              height: 45px;
              font-size: 1.8rem;
              background: white;
              border-radius: 50%;
              color: #4477ba;
              i {
                margin: auto;
              }
              &:hover,
              &:focus,
              &:active {
                background: #d7e8ff;
              }
            }
            @media (min-width: 320px) and (max-width: 480px) {
              bottom: 48px;
            }
          }
        }
        .copyright-inner {
          .left-content-area {
            text-align: right;
            @media (min-width: 320px) and (max-width: 480px) {
              text-align: center;
            }
          }
        }
        @media (max-width: 767px) {
          margin-left: 2rem;
          .footer-widget {
            justify-content: flex-start;
          }
          .copyright-inner {
            width: 100%;
            margin-left: 0 !important;
            padding-left: 0 !important;
          }
        }
        @media (max-width: 480px) {
          margin-left: 1rem;
          .footer-widget {
            justify-content: flex-start;
            li {
              font-size: 0.9rem;
            }
          }
          .social-icon a {
            width: 38px !important;
            height: 38px !important;
          }
          .copyright-inner {
            width: 100%;
            margin-left: 0 !important;
            padding-left: 0 !important;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

.text-black {
  color: black !important;
}

.search-title h3 {
  line-height: normal;
}

.searchForm ul li {
  color: #ccc;
}

.searchForm ul li b {
  color: white;
}

.search-content .btn {
  font-size: 15px;
}
/* TODO : Update Responsive */
body .contact-form-area .contact-area-wrapper .submit-btn {
  margin-bottom: 10px;
}
body .footer-area .footer-background {
  background-position: inherit;
  background-repeat: round;
  background-size: cover;
}
body .contact-form-area .contact-area-wrapper .submit-btn {
  margin-bottom: 10px;
}
@media only screen and (max-device-width: 767px) {
  .Shortcode-label {
    z-index: 9999999;
    position: relative;
    padding-bottom: 10px;
  }
  .Keyword-label {
    z-index: 999999;
    position: relative;
  }

  .contact-area-wrapper {
    padding: 0;
  }
  body .why-choose-area.why-choose-us-bg .contentOne .single-why-us-item .icon {
    z-index: 1;
  }
}
/* =======Responsiveness====================*/

body {
  .why-choose-area.why-choose-us-bg {
    .contentOne,
    .contentTwo {
      .single-why-us-item {
        height: 199px;
      }
    }
  }
}
@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
  .header-area.header-bg {
    height: 760px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  body {
    .contact-form-area {
      .contact-area-wrapper {
        padding: 0 !important;
      }
    }
  }
}
.kt-store__width {
  margin: 2px 0;
  display: block;
  img {
    width: 150px;
  }
}

.kt-store__badge {
  margin: 2px 0;
  display: block;
  img {
    height: 48px;
    width: auto;
  }
}

.Shortcode-label {
  div[class$="singleValue"] {
    text-transform: capitalize;
    font-weight: bold;
  }
  div[class$="menu"] {
    text-transform: capitalize;
    font-weight: bold;
  }
}

input:-internal-autofill-selected {
  background-color: #005daa !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.footer-widget {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .copyright-inner {
    color: white;
    font-size: 1.1rem;
    margin-left: 5rem;
  }
  &.additional-info {
    li {
      font-size: 1.1rem;
      margin-left: 20px;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.single-why-us-item {
  .icon {
    padding: 9px;
    box-shadow: 0px 3px 17px -5px rgba(42, 0, 117, 0.4);
    transform: translate(-50%, -50%);
    margin: 0;
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 0;
    svg.kt-svg-icon {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      width: 48px;
      height: 48px;
      g [fill] {
        fill: #fff !important;
      }
    }
  }
  .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    p {
      font-size: 1.3rem;
    }
  }
}

.container {
  @media (max-width: 992px) {
    max-width: 840px;
  }
}
.faq {
  h4 {
    color: #272727;
  }
  p {
    font-size: 14px;
  }
}
.services {
  ol {
    list-style-type: circle;
    line-height: 25px;
    color: #272727;
  }
}
.aboutus {
  h5,
  h4 {
    color: #272727;
  }
  .content {
    text-align: center;
    color: #272727;
    font-weight: 600;
  }
  ol {
    list-style-type: circle;
    color: #272727;
    font-weight: 500;
    line-height: 24px;
  }
  .experience {
    margin: 0 !important;
  }
}
.app-store-link {
  display: inline-block;
}
