/* ========= Contact Confirmation Page ==========*/

input {
  outline: none;
  border: none;
  .input3 {
    height: 45px;
  }
}
.reviewSection {
  color: white;
  padding: 21px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 20px 8px;
  }
  h3 {
    font-weight: bold;
  }
  label {
    color: #fff !important;
    font-size: 13.6px !important;
  }
}
.phoneImage {
  width: 275px;
  //margin-top: 135px;
  //margin-left: 25px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 42px !important;
  }
}
.bg-contact3 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(../../assets/images/bg1.jpg);
}
.container-contact3 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-family: "Roboto Slab", serif;
  background: rgba(148, 148, 148, 0.52);
  @media (max-width: 767px) {
    min-height: 80vh;
  }
}
.imgContainer {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 5%;
  margin-top: -90px;
  @media (min-width: 481px) and (max-width: 767px) {
    left: 0 !important;
    margin-top: -40px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    left: 0 !important;
  }
}

/* Contact Form */
.wrap-contact3 {
  margin-top: -200px;
  margin-right: 15px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 37px 57px 80px;
  box-shadow: 0px 1px 7px #c3c3c3;
  font-family: "Comfortaa", serif;
  @media (min-width: 481px) and (max-width: 767px) {
    margin: 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 20px 60px;
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  .contact3-form {
    width: 100%;
    label {
      color: #272727;
      font-weight: 600;
    }
    .contact3-form-title {
      display: block;
      color: #272727;
      text-align: left;
      text-transform: none;
      padding-bottom: 50px;
      h4 {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 20px;
        font-family: "Roboto Slab", serif;
        color: #272727;
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
          text-align: start !important;
          padding-bottom: 5px;
        }
      }
      span {
        font-size: 1rem;
        font-weight: 600;
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 0.75rem;
          line-height: 0.75rem;
        }
      }
      label {
        font-size: 1rem !important;
      }
      @media (min-width: 320px) and (max-width: 480px) {
        padding-bottom: 15px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 0 !important;
    padding: 17px 20px 27px;
    margin-bottom: 26px;
    margin-right: 0;
  }
}
.wrap-input3 {
  width: 100%;
  position: relative;
  // border-bottom: 2px solid rgba(216, 216, 216, 0.5);
  margin-bottom: 27px;
  .input3 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 15px;
    color: #272727;
    line-height: 1.2;
    padding: 0 5px;
    border-bottom: 1px solid #c9d1d8 !important;
    height: 45px;
    margin-bottom: 0.5rem;
    &:focus {
      outline: unset;
      border-color: #005daa;
    }
    &:focus + .focus-input3::before {
      width: 100%;
      color: #272727;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      height: 20px;
    }
  }
  .focus-input3 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: #fff;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin-bottom: 10px;
  }
}

// #phoneLogo {
//     width: 367px;
//     height: 575px;
// }
#redoBtn {
  font-family: "Comfortaa", serif;
  border: none;
  font-size: 1.1rem;
  width: 100%;
  line-height: 1.3;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 140px;
    font-size: 1rem;
    line-height: 1.1;
    top: -30px;
  }
}
.container-contact3-form-btn {
  .bg-warning {
    float: none !important;
    top: 25px;
    position: relative;
    font-size: 15px;
    padding: 5px 15px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
}
.formContent {
  background: #4477ba;
  position: relative;
  height: 450px;
  border-radius: 10px;
  z-index: 4;
  margin-top: 50px;
}

.show-zips {
  max-height: 220px;
  overflow: hidden;
  overflow-y: inherit;
  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
}

.error {
  color: red !important;
}

.btn-zipcode {
  margin-right: 8px;
  font-size: 13px;
  margin-bottom: 8px;
  width: 65px;
  padding: 6px 0 4px 0;
  color: #212529;
  background-color: white !important;
  cursor: default !important;
  &:hover {
    background-color: white !important;
    color: #212529;
    cursor: default !important;
  }
  &:focus {
    background-color: white !important;
    color: #212529;
    cursor: default !important;
  }
  &:active {
    background-color: white !important;
    color: #212529;
    cursor: default !important;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .contact3-form-center {
    button {
      width: 100% !important;
    }
  }
  .container-contact3 {
    .imgContainer {
      left: 5%;
    }
    .phoneImage {
      margin-top: 30px !important;
      margin-left: 0;
      width: 165px !important;
    }
  }
  .redo-keyword {
    position: absolute;
    right: 15px;
    //top:50px;
  }
}
