/* ========= Search Form Style ==========*/
.searchform-wrapper {
  ::placeholder {
    opacity: 1;
  }
  .search-title h1 {
    font-weight: 700;
    line-height: 40px;
  }
  .search-content {
    .search-button {
      @media (min-width: 320px) and (max-width: 480px) {
        top: 13px !important;
        z-index: 2;
        width: 250px;
        right: 46px;
      }
    }
  }
  .searchForm {
    padding-top: 1rem;
    font-size: 1rem;
    text-align: left;
    color: white;
    @media (min-width: 320px) and (max-width: 480px) {
      padding-top: 0;
      font-size: 13px;
    }
    form {
      margin: 0;
      .selection {
        display: -webkit-box;
        @media (min-width: 320px) and (max-width: 480px) {
          display: -webkit-inline-box;
          margin: 0 !important;
        }
      }
      .form-control {
        height: 50px;
        background: transparent !important;
        // color: $secondary-color-3;
        border: none !important;
        // border-bottom: 1px solid $secondary-color-3!important;
        width: auto !important;
        margin: 9px 0;
        font-size: 1.1rem !important;
        &::placeholder {
          color: #80aed5 !important;
        }
        &:focus {
          background-color: transparent !important;
          // color: $secondary-color-3!important;
          border: none !important;
          box-shadow: none !important;
          // border-bottom: 1px solid $secondary-color-3!important;
        }
      }
      .distance {
        #input {
          height: 50px;
          background: transparent !important;
          &:focus {
            // color: $secondary-color-3!important;
            // border-bottom: 1px solid $secondary-color-3!important;
            @media (min-width: 320px) and (max-width: 480px) {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }

  .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #80aed5;
    font-size: 17px;
  }
  .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #80aed5;
  }
  .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #80aed5;
  }
  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #80aed5;
  }

  .Keyword-label label,
  .Shortcode-label label,
  .address label,
  .distance label {
    // color: $secondary-color-3!important;
    font-size: 13px;
    text-align: left;
    color: white;
    // margin-top: 15px;
  }
  .address label,
  .distance label {
    margin-top: 10px;
  }

  .location-search-input {
    padding-right: 5px;
    background-color: transparent;
    border: none;
    width: 95%;
    color: white;
    &::placeholder {
      color: #4477ba !important;
    }
    font-size: 1.1rem;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 90% !important;
    }
  }
  .autocomplete-dropdown-container {
    color: black;
    position: absolute;
    z-index: 5;
    padding: 5px 0;
    background: white;
    border-radius: 5px;
    .suggestion-item {
      padding: 8px 16px;
      margin: 0 8px;
      background-color: #fff;
      cursor: pointer;
      color: black;
    }
    .suggestion-item--active {
      padding: 8px 16px;
      margin: 0 8px;
      background-color: #4477ba;
      cursor: pointer;
      color: #fff;
      border-radius: 20px;
    }
  }
  .closingBtn {
    background-color: transparent;
    border: none;
    // color: $secondary-color-3;
  }

  /* Validation error */
  .error-content {
    color: #f91f1f !important;
    font-weight: 300;
    font-style: normal;
    font-size: 13px;
  }

  .search-container {
    border-bottom: 1px solid white;
  }

  #distance {
    background: transparent !important;
    border: none !important;
    font-size: 1.1rem;
    display: inline-block;
    color: white;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    width: 100px !important;
    height: 29px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 80px !important;
    }
  }

  .address {
    padding-right: 15px !important;
  }

  .distance {
    padding-left: 15px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
