/*!
 * Bootstrap v2.3.0-j4
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat, extended by @ArnoldDaniels.
 */
 .clearfix{*zoom:1;}.clearfix:before,.clearfix:after{display:table;content:"";line-height:0;}
 .clearfix:after{clear:both;}
 .hide-text{font:0/0 a;color:transparent;text-shadow:none;background-color:transparent;border:0;}
 .input-block-level{display:block;width:100%;min-height:30px;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
 .btn-file{overflow:hidden;position:relative;vertical-align:middle;}.btn-file>input{position:absolute;top:0;right:0;margin:0;opacity:0;filter:alpha(opacity=0);transform:translate(-300px, 0) scale(4);font-size:23px;direction:ltr;cursor:pointer;}
 .fileupload .uneditable-input{display:inline-block;margin-bottom:0px;vertical-align:middle;cursor:text;background: #fff;}
 .fileupload .thumbnail{overflow:hidden;display:inline-block;margin-bottom:5px;vertical-align:middle;text-align:center;}.fileupload .thumbnail>img{display:inline-block;vertical-align:middle;max-height:100%;}
 .fileupload .btn{vertical-align:middle; line-height: 21px; margin-left: -5px; }
 .fileupload-exists .fileupload-new,.fileupload-new .fileupload-exists{display:none;}
 .fileupload-inline .fileupload-controls{display:inline;}
 .fileupload-new .input-append .btn-file{-webkit-border-radius:0 2px 2px 0;-moz-border-radius:0 2px 2px 0;border-radius:0 2px 2px 0;}
 .thumbnail-borderless .thumbnail{border:none;padding:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;}
 .fileupload-new.thumbnail-borderless .thumbnail{border:1px solid #ddd;}
 .control-group.warning .fileupload .uneditable-input{color:#a47e3c;border-color:#a47e3c;}
 .control-group.warning .fileupload .fileupload-preview{color:#a47e3c;}
 .control-group.warning .fileupload .thumbnail{border-color:#a47e3c;}
 .control-group.error .fileupload .uneditable-input{color:#b94a48;border-color:#b94a48;}
 .control-group.error .fileupload .fileupload-preview{color:#b94a48;}
 .control-group.error .fileupload .thumbnail{border-color:#b94a48;}
 .control-group.success .fileupload .uneditable-input{color:#468847;border-color:#468847;}
 .control-group.success .fileupload .fileupload-preview{color:#468847;}
 .control-group.success .fileupload .thumbnail{border-color:#468847;}
 .uneditable-input {
     border: 1px solid #ccc; height: 35px; padding: 6px 10px; width: 200px; overflow: hidden;
     -moz-border-radius: 2px; -webkit-border-radius: 2px; border-radius: 2px; }
 .uneditable-input .glyphicon { float: left; margin-right: 5px; vertical-align: middle; margin-top: 2px; opacity: 0.5; }
 .fileupload-preview { width: 400px; display: block; color: #666; }
 