.consultation-container {
  background-color: #4477ba;
  display: flex;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  .consultation-wrapper {
    background-color: #333;
    width: 400px;
    height: 600px;
    border-radius: 5px;
    box-shadow: 0 0 5px #333;
    .consultation-header {
      background: #333;
      border-radius: 5px 5px 0 0;
      color: white;
      padding: 10px 20px;
    }
    .consultation-body {
      background-color: white;
      border-radius: 5px;
      padding: 20px;
      label {
        width: 100%;
        margin-bottom: 15px;
        ::placeholder {
          color: #ccc;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: #ccc;
        }
        ::-ms-input-placeholder {
          color: #ccc;
        }
      }
      .submit-btn {
        background: #f5a800;
        color: white;
        font-size: 1rem;
        &:hover {
          background: #003764;
          color: white;
        }
      }
    }
  }
}
