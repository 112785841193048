body {
  color: #272727;
}

.headline {
  h2,
  p {
    margin-left: 60px;
  }
}

.sub-headline {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.form-section {
  margin: 25px 60px;
}
.custom-landing-form {
  border-bottom: 1px solid #bdbfbf;
  .form-control {
    border: none;
    background-color: transparent;
  }
}
.profile-container {
  background: #fff;
  width: 100%;
  margin: 20px 40px;
  border-radius: 15px;
  @media (max-width: 480px) {
    margin: 15px;
  }
  content: "";
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  -moz-border-radius: 100px / 10px;
  .little-description {
    padding: 2.5rem;
  }
}

.nav {
  border-right: 1px solid #ccc;
  a {
    padding-left: 2.5rem;
    border-radius: 0 !important;
    color: black;
  }

  a:last-child {
    margin-bottom: 25px !important;
  }

  .active {
    background: #f7d532 !important;
    color: black !important;
  }

  .menu-container {
    margin-bottom: 25px;
  }
}
