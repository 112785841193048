.kt-switch {
  width: 100%;
}

.search-input {
  border: 1px solid #4958677d !important;
  border-radius: 28px;
  height: 32px;
  margin-top: 9px;
  float: right;
  &::placeholder {
    padding-left: 10px;
  }
  &:focus {
    color: #272727;
    padding-left: 10px;
    outline: none;
  }
}

.search {
  position: relative;
  float: right;
  right: 35px;
  top: 13px;
}

.business-list {
  position: relative;
  .business-list-tools {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    .kt-subheader__search {
      display: inline-block;
      width: 200px;
    }
  }
}
