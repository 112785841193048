.kt-report__range {
  line-height: 38px;
  color: #272727;
  padding-right: 5px;
  margin-bottom: 10px;
}
.kt-report {
  box-shadow: unset !important;
}
.bp3-input {
  height: 38px !important;
}
.css-1wa3eu0-placeholder {
  font-size: 12px;
}

.reports-tools {
  position: relative;
  margin-bottom: 1rem;
  .reports-tools-selection {
    .ddCompany {
      display: inline-block;
      width: 200px;
      margin-right: 15px;
    }
    .ddShortcode {
      display: inline-block;
      width: 200px;
      margin-right: 15px;
    }
    .ddKeyword {
      display: inline-block;
      width: 200px;
    }
  }
  .reports-tools-datepicker {
    position: absolute;
    top: 0;
    right: 20px;
    @media (max-width: 1500px) {
      position: relative;
      right: 0;
      .kt-report__range {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }
}
