/* ========= Configuration Page Style ==========*/

#kt_wrapper {
  #kt_content {
    .search-input {
      border: 1px solid #4958677d !important;
      border-radius: 28px;
      height: 32px;
      margin-top: 9px;
      float: right;
      margin-bottom: 10px;
      &::placeholder {
        padding-left: 10px;
      }
      &:focus {
        color: #272727;
        padding-left: 10px;
        outline: none;
      }
    }
    .search {
      position: relative;
      float: right;
      right: 35px;
      top: 13px;
    }
    .kt-portlet {
      box-shadow: unset;
      .add-field {
        margin: 20px 0 0 0;
        .kt-input-icon {
          input {
            border-radius: 23px;
            &:focus {
              border: 1px solid #4477ba;
            }
          }
        }
        .kt-svg-icon g [fill] {
          fill: white !important;
        }
      }
    }
  }
}

.keyword-list {
  position: relative;
  margin-bottom: 0.5rem;
  .keyword-list-tools {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    .kt-subheader__search {
      display: inline-block;
      width: 200px;
      @media (max-width: 1199px) {
        width: 160px;
      }
      @media (max-width: 767px) {
        width: 200px;
      }
    }
  }
}

.shortcode-list {
  position: relative;
  margin-bottom: 0.5rem;
  .shortcode-list-tools {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    .kt-subheader__search {
      display: inline-block;
      width: 200px;
      @media (max-width: 1199px) {
        width: 150px;
      }
      @media (max-width: 767px) {
        width: 200px;
      }
    }
  }
}
