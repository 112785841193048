.locationLink {
  height: 100vh;
  background-image: url(../locationLink/images/bg.jpg);
  background-size: cover;
  display: flex;
  .locationLink-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 770px;
    align-items: center;
    z-index: 1;
    .title {
      font-family: "Roboto Slab";
      font-size: 64px;
      margin-bottom: 40px;
    }
    .desc {
      font-size: 24px;
      font-weight: 400;
      font-family: "Roboto";
      color: white;
    }
    .locationBtn {
      font-family: "Roboto";
      font-size: 24px;
      font-weight: 300;
      color: white;
      text-decoration: none;
      display: inline-block;
      margin-top: 40px;
      &:hover {
        color: white;
        text-decoration: underline;
      }
      &::before {
        content: " ";
        background-image: url(../locationLink/images/locationPin.png);
        image-rendering: optimizeQuality;
        height: 31px;
        width: 19px;
        display: inline-block;
        background-size: cover;
        margin-right: 12px;
      }
    }
    .or-text {
      font-size: 24px;
      font-weight: 300;
      font-family: "Roboto";
      color: white;
      margin-bottom: 8px;
    }
    .submitForm {
      display: flex;
      flex-direction: row;
      border: 2px solid white;
      max-width: 600px;
      width: 100%;
      height: 45px;
      .custom-landing-form {
        font-family: "Roboto";
        font-size: 24px;
        margin-bottom: 0 !important;
        flex-grow: 1;
        .locationInput {
          padding-left: 25px;
          background-color: transparent;
          color: white;
          border-radius: 0;
          width: 100%;
          margin-bottom: 0;
          border-bottom: none;
          height: 41px;
          &::placeholder {
            color: #ccc;
          }
        }
      }
      .submitBtn {
        flex-basis: 140px;
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 700;
        color: black;
        background-color: white;
        padding: 8px 0;
        border: none;
        cursor: pointer;
        outline: none;
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .locationLink {
    .locationLink-container {
      max-width: 575px;
      .title {
        font-size: 48px;
      }
      .desc {
        font-size: 18px;
      }
      .locationBtn {
        font-size: 18px;
      }
      .or-text {
        font-size: 18px;
      }
      .submitForm {
        max-width: 400px;
        .custom-landing-form {
          font-size: 18px;
        }
        .submitBtn {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .locationLink {
    .locationLink-container {
      max-width: 430px;
      .title {
        font-size: 36px;
      }
      .desc {
        font-size: 14px;
      }
      .locationBtn {
        font-size: 14px;
      }
      .or-text {
        font-size: 14px;
      }
      .submitForm {
        max-width: 350px;
        .custom-landing-form {
          font-size: 14px;
        }
        .submitBtn {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .locationLink {
    .locationLink-container {
      padding: 0 30px 100px 30px;
      .title {
        font-size: 24px;
      }
      .or-text {
        margin: 10px 0;
      }
    }
  }
}
