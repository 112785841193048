.node-title {
  font-size: 16px;
  font-weight: bold;
}
.question-item {
  position: relative;
  min-width: 300px;
  max-width: 500px;
  background: #eee;
  background: #89d97c;
  border-radius: 5px;
  border: 1px solid #89d97c;
  padding: 10px 15px;
}
.package-tool {
  position: absolute;
  top: 0;
  right: -70px;
  .package-tool-link {
    margin: 0 5px;
    font-size: 1.5rem;
  }
}
.message-tree .ant-tree {
  background: transparent;
  .ant-tree-switcher {
    background: transparent;
  }
}
